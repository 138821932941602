var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"associationEdit"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"分类名称","rules":[
        { required: true, message: '请输入分类名称', trigger: 'blur' },
      ],"prop":"associationName"}},[_c('v-input',{attrs:{"placeholder":"请输入分类名称","width":_vm.width},model:{value:(_vm.form.associationName),callback:function ($$v) {_vm.$set(_vm.form, "associationName", $$v)},expression:"form.associationName"}})],1),_c('el-form-item',{attrs:{"label":"社群负责人","rules":[
        { required: true, message: '请输入社群负责人', trigger: 'blur' },
      ],"prop":"userId"}},[_c('v-select',{attrs:{"filterable":"","placeholder":"请输入社群负责人","clearable":"","options":_vm.directorList},model:{value:(_vm.form.userId),callback:function ($$v) {_vm.$set(_vm.form, "userId", $$v)},expression:"form.userId"}})],1),_c('el-form-item',{attrs:{"label":"社群参与人数","rules":[
        { required: false, message: '请输入社群参与人数', trigger: 'blur' },
      ],"prop":"memberNum"}},[_c('v-input',{attrs:{"placeholder":"请输入社群参与人数","min":"1","width":_vm.width,"type":"number","disabled":""},model:{value:(_vm.form.memberNum),callback:function ($$v) {_vm.$set(_vm.form, "memberNum", $$v)},expression:"form.memberNum"}})],1),_c('el-form-item',{attrs:{"label":"社群组织方","rules":[
        { required: false, message: '请选择社群组织方', trigger: 'blur' },
      ],"prop":"organiser"}},[_c('v-select',{attrs:{"clearable":"","options":_vm.associationOrg},model:{value:(_vm.form.organiser),callback:function ($$v) {_vm.$set(_vm.form, "organiser", $$v)},expression:"form.organiser"}})],1),_c('el-form-item',{attrs:{"label":"社群成立时间","rules":[
        { required: true, message: '请选择社群成立时间', trigger: 'blur' },
      ],"prop":"establishTime"}},[_c('v-date-picker',{attrs:{"format":"yyyy-MM-dd HH:mm:ss","formatValue":"yyyy-MM-dd HH:mm:ss","type":"datetime"},model:{value:(_vm.form.establishTime),callback:function ($$v) {_vm.$set(_vm.form, "establishTime", $$v)},expression:"form.establishTime"}})],1),_c('el-form-item',{attrs:{"label":"排序","rules":[
        {
          required: true,
          message: '请输入置顶排序值',
          trigger: ['blur', 'change'],
        },
        {
          message: '必须是数字格式',
          pattern: /^[0-9]+$/,
          trigger: 'blur',
        },
      ],"prop":"sort"}},[_c('v-input',{attrs:{"placeholder":"请输入排序值","min":"1","width":_vm.width,"type":"number"},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}})],1),_c('el-form-item',{attrs:{"label":"图片","rules":[
        {
          required: true,
          message: '请上传图片',
          trigger: 'blur',
        },
      ],"prop":"picture"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.picture},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "picture", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "picture", $event)}}})],1),_c('el-form-item',{attrs:{"label":"关键词","rules":[{ required: true, message: '请输入关键词', trigger: 'blur' }],"prop":"content"}},[_c('v-input',{attrs:{"placeholder":"请输入关键词","width":_vm.width},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('el-form-item',{attrs:{"label":"社群介绍","rules":[
        { required: true, message: '请输入社群介绍', trigger: 'blur' },
      ],"prop":"description"}},[_c('v-input',{attrs:{"placeholder":"请输入社群介绍","width":220,"type":"textarea","textAreaRows":10},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }