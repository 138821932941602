<template>
  <div class="associationEdit">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="分类名称"
        :rules="[
          { required: true, message: '请输入分类名称', trigger: 'blur' },
        ]"
        prop="associationName"
      >
        <v-input
          placeholder="请输入分类名称"
          :width="width"
          v-model="form.associationName"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="社群负责人"
        :rules="[
          { required: true, message: '请输入社群负责人', trigger: 'blur' },
        ]"
        prop="userId"
      >
        <v-select
          filterable
          placeholder="请输入社群负责人"
          clearable
          :options="directorList"
          v-model="form.userId"
        />
      </el-form-item>
      <el-form-item
        label="社群参与人数"
        :rules="[
          { required: false, message: '请输入社群参与人数', trigger: 'blur' },
        ]"
        prop="memberNum"
      >
        <v-input
          placeholder="请输入社群参与人数"
          v-model="form.memberNum"
          min="1"
          :width="width"
          type="number"
          disabled
        ></v-input>
      </el-form-item>
      <el-form-item
        label="社群组织方"
        :rules="[
          { required: false, message: '请选择社群组织方', trigger: 'blur' },
        ]"
        prop="organiser"
      >
        <v-select
          clearable
          :options="associationOrg"
          v-model="form.organiser"
        />
      </el-form-item>
      <el-form-item
        label="社群成立时间"
        :rules="[
          { required: true, message: '请选择社群成立时间', trigger: 'blur' },
        ]"
        prop="establishTime"
      >
        <v-date-picker
          v-model="form.establishTime"
          format="yyyy-MM-dd HH:mm:ss"
          formatValue="yyyy-MM-dd HH:mm:ss"
          type="datetime"
        ></v-date-picker>
      </el-form-item>

      <!-- <el-form-item
        label="上级分类"
        :rules="[
          { required: false, message: '请选择上级分类', trigger: 'blur' },
        ]"
        prop="parentId"
      >
        <v-select
          clearable
          :options="associationList"
          v-model="form.parentId"
        />
      </el-form-item>
      <el-form-item
        label="社群年龄"
        :rules="[
          { required: true, message: '请选择社群年龄', trigger: 'blur' },
        ]"
        prop="ageStage"
      >
        <v-select clearable :options="ageStageList" v-model="form.ageStage" />
      </el-form-item> -->
      <el-form-item
        label="排序"
        :rules="[
          {
            required: true,
            message: '请输入置顶排序值',
            trigger: ['blur', 'change'],
          },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="sort"
      >
        <v-input
          placeholder="请输入排序值"
          v-model="form.sort"
          min="1"
          :width="width"
          type="number"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="图片"
        :rules="[
          {
            required: true,
            message: '请上传图片',
            trigger: 'blur',
          },
        ]"
        prop="picture"
      >
        <v-upload :limit="1" :imgUrls.sync="form.picture" />
      </el-form-item>

      <el-form-item
        label="关键词"
        :rules="[{ required: true, message: '请输入关键词', trigger: 'blur' }]"
        prop="content"
      >
        <v-input
          placeholder="请输入关键词"
          :width="width"
          v-model="form.content"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="社群介绍"
        :rules="[
          { required: true, message: '请输入社群介绍', trigger: 'blur' },
        ]"
        prop="description"
      >
        <v-input
          placeholder="请输入社群介绍"
          :width="220"
          v-model="form.description"
          type="textarea"
          :textAreaRows="10"
        ></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  getAssociaKindsListUrl,
  getKindsUrl,
  getKindsDetailUrl,
  updateKindsUrl,
  getDirectorUrl,
} from "./api.js";
import moment from "moment";
import { associationOrg, associationOrgMap } from "./map.js";
import { mapHelper } from "@/utils/common.js";
export default {
  name: "associationEdit",
  data() {
    return {
      directorList: [], //负责人列表
      associationOrg,
      associationList: [],
      ageStageList: [],
      width: 220,
      parentId: "",
      id: "",
      communityList: [],
      submitConfig: {
        queryUrl: getKindsDetailUrl,
        submitUrl: getKindsUrl,
      },
      form: {
        id: this.id,
        organiser: "",
        establishTime: null,
        tenantId: this.$store.state.app.userInfo.tenantId,
        optUser: Number(this.$store.state.app.userInfo.userId),
        associationName: "",
        userId: "",
        memberNum: 1,
        ageStage: "",
        sort: "",
        picture: "",
        content: "",
        description: "",
        parentId: "",
        directorListOps: null,
      },
    };
  },
  created() {
    this.getDirectorList();
    this.getAssociationList();

    const { id, parentId } = this.$route.query;
    console.log(id, parentId, 887);
    this.id = id;
    this.form.parentId = parentId;
    // this.form.communityId = communityId;
    if (id !== undefined) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({ id });
      });
    }
    this.submitConfig.submitUrl =
      id !== undefined ? updateKindsUrl : getKindsUrl;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    getDirectorList() {
      //获取社群负责人数据
      this.$axios.get(`${getDirectorUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.value = item.userId;
            item.label = `${item.userName} ${item.mobile}`;
            this.directorList.push(item);
          });
        }
      });
    },
    getAssociationList() {
      this.associationList = [];
      this.$axios.get(`${getAssociaKindsListUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.associationLv1List.forEach((item) => {
            item.label = item.value;
            item.value = item.key;
            this.associationList.push(item);
          });
          res.data.ageStageList.forEach((item) => {
            item.label = item.dictValue;
            item.value = item.dictKey;
            this.ageStageList.push(item);
          });
        }
      });
    },

    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
        // if (this.form.establishTime && this.form.establishTime.length > 0) {
        //   this.form.establishTime = new Date(this.form.establishTime);
        // }
      });
    },

    submitBefore() {
      // this.form.establishTime = moment(this.form.establishTime).format(
      //   "YYYY-MM-DD HH:mm:ss"
      // );
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.associationEdit {
  box-sizing: border-box;
  height: 100%;
}
</style>
